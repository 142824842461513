<template>
    <div class="wrap-html-run">
        <div class="top-bar" v-if="isShowTopBar">
            <span class="btn btn-run" @click="onRunCodeHandler"
                  data-report-click='{"spm":"3001.4484","extend1":"在线运行代码按钮"}'><i class="icon"></i>运行</span>
            <span class="btn btn-show" @click="onToggleCodeHandler(1)" v-bind:class="{hide: isShowEditor}"
                  data-report-click='{"spm":"3001.4486","extend1":"显示代码按钮"}'>
                <i class="icon"></i>显示代码
            </span>
            <span class="btn btn-hide" @click="onToggleCodeHandler(0)" v-bind:class="{hide: !isShowEditor}"
                  data-report-click='{"spm":"3001.4487","extend1":"隐藏代码按钮"}'>
                <i class="icon"></i>隐藏代码
            </span>
            <!--<span class="btn btn-format" @click="onFormattingHandler">格式化</span>-->
        </div>
        <div class="html-main" v-bind:class="{active: !isShowTopBar}">
            <div class="html-main-l" v-bind:class="{hide: !isShowEditor}">
                <textarea ref="textarea"></textarea>
            </div>
            <div class="html-main-line" v-if="isShowEditor"></div>
            <div class="html-main-r" v-bind:class="{full: !isShowEditor}">
                <iframe id="preview" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import _CodeMirror from 'codemirror'

    import 'codemirror/lib/codemirror.css'
    import 'codemirror/addon/hint/show-hint.css'
    //import 'codemirror/theme/neat.css'
    import 'codemirror/mode/htmlmixed/htmlmixed'
    import 'codemirror/mode/css/css'
    import 'codemirror/mode/javascript/javascript'
    import 'codemirror/mode/xml/xml'
    import 'codemirror/mode/vue/vue'

    import 'codemirror/addon/hint/css-hint'
    import 'codemirror/addon/hint/javascript-hint'
    import 'codemirror/addon/hint/html-hint'
    import 'codemirror/addon/hint/show-hint'

    //格式化html
    import html from 'html';

    const CodeMirror = window.CodeMirror || _CodeMirror;

    function escape2Html(str) {
        var temp = document.createElement("div");
        temp.innerHTML = str;
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }

    export default {
        name: "index",
        data() {
            return {
                // 内部真实的内容
                code: "",
                // 打底数据
                initCode: "<h1>Hello World</h1>",
                // 编辑器实例
                editor: null,
                //定时器实例
                timer: null,
                // 默认配置
                options: {
                    // 默认的语法类型
                    mode: 'htmlmixed',
                    // 缩进格式
                    tabSize: 2,
                    // 主题，对应主题库 JS 需要提前引入
                    //theme: 'neat',
                    // 显示行号
                    lineNumbers: true,
                    matchBrackets: true,
                    line: true,
                    extraKeys: {Ctrl: 'autocomplete'},
                    hintOptions: {
                        completeSingle: false
                    }
                },
                params: {},
                isShowEditor: true,
                isShowTopBar: true,
            }
        },
        mounted() {
            // 获取参数
            this.params = this.$router.history.current.params;

            // 初始化
            this._initialize();

        },
        methods: {
            /*
            *
            * 根据query词和id获取对应的代码
            *
            * */
            getCodeStr(id, cb) {
                this.$ajax.get('/api/v1/search_by_id?t=code&&ids=' + id).then(res => {
                    cb(res)
                }).catch((e) => {
                    console.log(e)
                });
            },
            /*
            *
            * 初始化CodeMirror
            *
            * */
            _initialize: function () {
                //let self = this;

                this.editor = CodeMirror.fromTextArea(this.$refs.textarea, this.options);


                this.initCodeContent();

                //this.editor.setValue(this.code);
                //this.codePreview();

                //show hint
                this.editor.on("inputRead", () => {
                    this.editor.showHint();
                });

                // this.editor.on('change', function () {
                //     clearTimeout(self.timer);
                //     self.timer = setTimeout(self.codePreview, 300);
                // })

            },
            /*
            *
            * code预览
            *
            * */
            codePreview: function () {
                let previewFrame = document.getElementById('preview');
                let preview = previewFrame.contentDocument || previewFrame.contentWindow.document;
                preview.open();
                preview.write(this.editor.getValue());
                preview.close();
            },
            /*
            *
            * run代码
            *
            * */
            onRunCodeHandler() {
                this.codePreview();
            },
            /*
            *
            * 是否显示代码
            *
            * */
            onToggleCodeHandler(flag) {
                this.isShowEditor = flag;
            },
            /*
            *
            * html格式化
            *
            * */
            onFormattingHandler() {
                let code = this.editor.getValue();
                let formatCode = html.prettyPrint(code);
                this.editor.setValue(formatCode);
            },
            /*
            *
            * 初始化代码内容
            *
            * */
            initCodeContent() {
                let {id, flag} = this.params;
                if (flag) {
                    this.isShowTopBar = flag == 1 ? true : false;
                }
                if (id) {
                    //取到数据回填显示 104428697-21
                    this.getCodeStr(id, (res) => {
                        if (res.status === 200) {
                            let data = res.data;
                            let str = "";
                            if (data && data.data.length) {
                                str = data.data[0].body;
                                this.code = escape2Html(str);
                                this.editor.setValue(this.code);
                                this.isShowEditor = false;
                                this.codePreview();
                            }
                        }
                    })

                } else {
                    this.editor.setValue(this.initCode);
                    this.codePreview();
                }
            }
        }
    }
</script>

<style>
    body {
        overflow: hidden;
    }

    .wrap-html-run .hide {
        display: none !important;
    }

    .wrap-html-run .top-bar {
        display: flex;
        height: 60px;
        align-items: center;
        padding: 0 30px;
        border: 1px solid #ececec;
    }

    .wrap-html-run .top-bar .btn {
        color: #39464E;
        margin-right: 16px;
        cursor: pointer;
    }

    .wrap-html-run .top-bar .btn .icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        vertical-align: text-top;
    }

    .wrap-html-run .top-bar .btn-run .icon {
        background: url("../../assets/images/play.png") no-repeat;
        background-size: 100%;
    }

    .wrap-html-run .top-bar .btn-show .icon {
        background: url("../../assets/images/show.png") no-repeat;
        background-size: 100%;
    }

    .wrap-html-run .top-bar .btn-hide .icon {
        background: url("../../assets/images/hide.png") no-repeat;
        background-size: 100%;
    }

    .wrap-html-run .html-main {
        height: calc(100vh - 50px);
    }

    .wrap-html-run .html-main.active {
        height: 100vh;
    }

    .wrap-html-run .html-main.active #preview {
        height: 100vh;
    }

    .wrap-html-run .html-main .html-main-l,
    .wrap-html-run .html-main .html-main-r {
        width: calc(50% - 5px);
        height: 100%;
        float: left;
    }

    .wrap-html-run .html-main .html-main-r {
        overflow: hidden;
    }

    .wrap-html-run .html-main .html-main-line {
        position: relative;
        width: 10px;
        height: 100%;
        float: left;
        /*cursor: col-resize;*/
        background-color: #f3f5f6;
    }

    .wrap-html-run .html-main .html-main-line::after {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 10;
        display: block;
        width: 1px;
        height: 100%;
        content: "";
        margin-left: -0.5px;
        background-color: #cfd6d9;
    }

    .wrap-html-run .html-main .html-main-r.full {
        width: 100% !important;
    }

    .wrap-html-run .CodeMirror {
        height: calc(100vh - 50px);
        background-color: #f3f5f6;
    }

    .wrap-html-run #preview {
        width: 100%;
        height: calc(100vh - 50px);
        background-color: #f3f5f6;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);

    }
</style>
